(function($) {
    var ease1 = Power4.easeInOut();
    var ease2 = Power4.easeIn();
    var ease3 = Power4.easeOut();

    var tl = gsap.timeline();

    tl.fromTo('.waves-top path', { drawSVG:"0" }, { duration: 1.3, drawSVG:"100%", ease: ease3 });
    tl.fromTo('.waves-bottom path', { drawSVG:"0" }, { duration: 1.3, drawSVG:"100%", ease: ease3 }, "<");
    tl.from('.wink-class', 3, {transform: "scaleY(0)", repeat: 1, yoyo: true}, 0);

    gsap.set('.animation, .animin', { visibility: 'visible'});

    var initFullpage = function() {
        var myFullpage = new fullpage('#fullpage', {
            licenseKey: "D621A729-6352415D-91C6802C-E36F5C0E",
            //Navigation
            menu: '#menu',
            anchors:[
                'hero',
                'videos',
                'foundedBy',
                'tailoredTo',
                'freeOfCharge',
                'baseline',
                'tableTop',
                'incidentResponse',
                'cyberCapability',
                'ourTeam',
                'upcomingEvents'
            ],
            lazyLoading: true,

            onLeave: function(origin, destination, direction){
                var leavingSection = this;
                var tl = gsap.timeline();
                var midPoint = window.innerWidth/2;
                var ease2 = Power4.easeIn();
                var dIndex = destination.index;

                function animateBrackets(titleRef) {
                    tl.from(`#brr${titleRef}`, 1, { x:-midPoint }, 0)
                        .from(`#brl${titleRef}`, 1, { x:midPoint }, 0);
                    tl.fromTo(`#animate-title-${titleRef}`,{opacity: '0'}, {duration: 2, opacity: '1', ease: ease2}, 0);
                    tl.fromTo(
                        `#animate-title-${titleRef}`,
                        1.2,
                        {clipPath:"polygon(50% 0%, 50% 0%, 50% 100%, 50% 100%)"},
                        {clipPath:"polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)", ease: ease2}, 0
                    );
                }
                // dIndex == 1 && animateBrackets(1);
                // dIndex == 2 && animateBrackets(2);
                // dIndex == 3 && animateBrackets(3);
                // dIndex == 8 && animateBrackets(4);
                // dIndex == 9 && animateBrackets(5);
                dIndex == 2 && animateBrackets(1);
                dIndex == 3 && animateBrackets(2);
                dIndex == 4 && animateBrackets(3);
                dIndex == 9 && animateBrackets(4);
                dIndex == 10 && animateBrackets(5);
            }
        });
        return myFullpage;
    }
    var myFullpage = initFullpage();
    function checkPageSize() {
        if (
            ($(window).height() < 755 && ($(window).width() > 478 || ($(window).width() / $(window).height() < 1.4)))
            ||
            $(window).width() < 400) {
            myFullpage.setResponsive(true);
        } else {
            myFullpage.setResponsive(false);
        }
    }
    checkPageSize();

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    $(window).on('resize', function(){
        checkPageSize();
    })

})(jQuery);























